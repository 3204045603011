.upload-chunk-wrapper {
    margin-top: 1rem;
}

.upload-chunk-wrapper > .upload-chunk {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.upload-chunk-wrapper > .upload-chunk > .upload-img-wrapper {
    display: flex;
    width: 33.3%;
    align-items: flex-start;
    position: relative;
}

.upload-chunk-wrapper > .upload-chunk > .upload-img-wrapper:not(:nth-child(3)) {
    padding-right: 0.5rem;
}

.upload-chunk-wrapper > .upload-chunk > .upload-img-wrapper > img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px solid var(--color-border);
    border-radius: .25rem;
}

.upload-chunk-wrapper > .upload-chunk > .upload-img-wrapper > button.upload-remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    background-color: var(--color-bg-pink);
    border: none;
    border-radius: 0.25rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}

.poi-form .playlist-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% ;
    margin-left: 0 ;
    background-color: var(--color-input);
    border-radius: 0.35rem;
    border: 1px solid var(--color-border);
    position: relative;
    cursor: pointer;
  }
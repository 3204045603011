.map-form {
  font-family: 'Inter', sans-serif;
}

.map-form .upload-image-preview {
  position: relative;
  width: 140px;
}

.map-form .upload-image-preview > img {
  width: 140px;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
}

.map-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: var(--color-danger);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-form .upload-drag-and-drop {
  background-color: #fff;
  padding: 1.5rem;
  width: 140px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  cursor: pointer;
}

.map-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.map-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.map-form .upload-drag-and-drop:hover,
.map-form .upload-drag-and-drop:hover > i,
.map-form .upload-drag-and-drop:hover > p,
.map-form .upload-drag-and-drop.active,
.map-form .upload-drag-and-drop.active > i,
.map-form .upload-drag-and-drop.active > p {
  border-color: var(--color-active);
  color: var(--color-active);
}

.base-layout {
  display: flex;
  min-height: 100vh;
  overflow-y: auto;
}


.base-layout .container {
  width: var(--screen-width);
  margin-left: 231px;
  transition: margin 0.3s ease-in-out;
}

.base-layout .blue-background {
  position: absolute;
  width: 100%;
  height: 252px;
  top: 0px;
  right: 0;
  background: #2C82F6;
}


.base-layout .container-collapsed {
  margin-left: 0px !important;
}

.container {
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

/* navbar */

.base-layout .navbar {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--color-stroke);
  /* padding: 1% 2%; */
  padding: 0 1%;
  height: 4.25rem;
  justify-content: flex-end;
  font-family: 'Inter', sans-serif;
}

.base-layout .navbar .profile-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.base-layout .navbar .profile-container .profile-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right !important;
}

.base-layout .navbar .profile-container .profile-name h3 {
  font-size: 1em;
  font-weight: 500;
  color: var(--color-active);
  margin: 0px;
}

.base-layout .navbar .profile-container .profile-name p {
  margin: 0px;
  color: var(--color-secondary);
  font-weight: 400;
}

.base-layout .navbar .profile-container .profile-picture>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.base-layout .navbar .profile-container .profile-setting {
  display: flex;
}

.base-layout .navbar .profile-container .profile-setting i {
  font-size: 2em;
  color: var(--color-active);
  cursor: pointer;
}

.base-layout .navbar .profile-dropdown {
  display: flex;
  position: absolute;
  padding: 1%;
  width: 200px !important;
  background-color: #fff;
  border: 1px solid var(--color-stroke);
  border-radius: 5px;
  right: 5px;
  transition: all 80ms ease-in-out;
  opacity: 0;
}

.non-admin {
  bottom: -100px;
}

.admin {
  bottom: -140px;
}

.base-layout .navbar .profile-dropdown>ul {
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.base-layout .navbar .profile-dropdown>ul>li:not(:last-child) {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-stroke);
  padding-bottom: 0.5rem;
}

.base-layout .navbar .profile-dropdown>ul>li,
.base-layout .navbar .profile-dropdown>ul>li>a {
  color: var(--color-black);
  cursor: pointer;
}

.base-layout .navbar .profile-dropdown>ul>li:hover,
.base-layout .navbar .profile-dropdown>ul>li:hover>a {
  color: var(--color-active);
}

.base-layout .navbar .profile-dropdown>ul>li>i,
.base-layout .navbar .profile-dropdown>ul>li>a>i {
  margin-right: 0.5rem;
}

.base-layout .navbar .profile-dropdown .triangle {
  position: absolute;
  top: -10px;
  right: 30px;
  z-index: -1;
  transition: all 100ms ease-in-out;
  opacity: 0;
}

/* content */

.base-layout .content-children {
  background-color: var(--color-bg-primary);
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.base-layout .overview {
  background-color: transparent;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.overview-breadcrumb{
  color: white !important;
}

@media (max-width: 1366px) {
  .base-layout .content-children {
    /* width: 768px; */
    width: 100%;
  }
}

@media (min-height: 960px) {
  .base-layout .content-children {
    min-height: calc(100vh - 7.75rem);
  }
}

/* footer */
.base-layout .footer {
  font-family: 'Inter', sans-serif;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--color-text-light);
  padding: 1% 2% !important;
  background-color: var(--color-bg-footer);
}

.base-layout .footer>span {
  margin: 0px;
  display: block;
}

/* replace antd style */
.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
}

.ant-modal-body .ant-form-item {
  display: flex;
  justify-content: center;
  /* padding: 0 3rem; */
}

.ant-form {
  width: 100%;
}

.map-form {
  width: 100%;
}


.navbar-header {
  padding: .5rem 2.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0px 10px 7px 2px rgba(0, 0, 0, 0.04); */
  /* cursor: pointer; */
  position: relative;
  background-color: var(--color-bg-primary);
  border-bottom: 1px solid var(--color-border);
  color: white;
}

.navbar-header>.navbar-right-overview>.profile-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-header>.navbar-right-overview>.profile-header>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.navbar-header>.navbar-right-overview>.profile-header>.profile-name {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
}

.navbar-header>.navbar-right-overview>.profile-header>.profile-name>p {
  margin: 0px;
}

.navbar-header>.navbar-right-overview>.profile-header>.profile-name>p.profile-title {
  font-family: 'Inter', sans-serif;
  color: white);
  font-weight: 600;
  font-size: .8rem;
}

.navbar-header>.navbar-right-overview>.profile-header>.profile-name>p.profile-subtitle {
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: .7rem;
}

.navbar-header>.navbar-right>.profile-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-header>.navbar-right>.profile-header>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.navbar-header>.navbar-right>.profile-header>.profile-name {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
}

.navbar-header>.navbar-right>.profile-header>.profile-name>p {
  margin: 0px;
}

.navbar-header>.navbar-right>.profile-header>.profile-name>p.profile-title {
  font-family: 'Inter', sans-serif;
  color: var(--color-primary);
  font-weight: 600;
  font-size: .8rem;
}

.navbar-header>.navbar-right>.profile-header>.profile-name>p.profile-subtitle {
  font-family: 'Inter', sans-serif;
  color: var(--color-secondary);
  font-size: .7rem;
}

.navbar-header .dropdown {
  position: absolute;
  top: 4rem;
  right: 1rem;
  background-color: #fff;
  width: 10rem;
  padding: .5rem 1rem;
  box-shadow: 0px 10px 7px 2px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

.navbar-header .dropdown>ul {
  margin: 0px;
  width: 100%;
}

.navbar-header .dropdown>ul>li,
.navbar-header .dropdown>ul>li>a {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-black);
  font-family: 'Inter', sans-serif;
}

.navbar-header .dropdown>ul>li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.navbar-header .dropdown>ul>li:hover,
.navbar-header .dropdown>ul>li:hover>a {
  color: var(--color-primary)
}

.navbar-header .dropdown>ul>li>i,
.navbar-header .dropdown>ul>li>a>i {
  margin-right: .5rem;
}

.navbar-header .dropdown.dropdown-close {
  opacity: 0;
  z-index: -2;
  transition: opacity ease-in-out 250ms;
}

.navbar-header .dropdown.dropdown-open {
  opacity: 1;
  z-index: 1;
  transition: opacity 250ms ease-in-out;
}

.sidebar-thumb {
  position: absolute;
  width: 4px;
  height: 150px;
  z-index: 10;
  background-color: #fff;
  border-radius: 4px;
  right: 0;
  top: 0;
}

.navbar-header .navbar-breadcrumb,
.navbar-header .navbar-breadcrumb>ul {
  margin: 0px;
}

.navbar-header .navbar-breadcrumb,
.navbar-header .navbar-breadcrumb>ul,
.navbar-header .navbar-breadcrumb>ul>li {
  display: flex;
  align-items: center;
}

.navbar-header .navbar-breadcrumb>ul>li.active {
  color: var(--color-text-primary);
}

.navbar-header .navbar-breadcrumb>ul>li:not(:last-child)::after {
  content: "\e930";
  margin: 0px .5rem;
  font-family: 'Feather';
  color: #9E9E9E;
}

.navbar-header .navbar-overview,
.navbar-header .navbar-overview>ul {
  margin: 0px;
}

.navbar-header .navbar-overview,
.navbar-header .navbar-overview>ul,
.navbar-header .navbar-overview>ul>li {
  display: flex;
  align-items: center;
}

.navbar-header .navbar-overview>ul>li.active,
.navbar-header .navbar-overview>ul>li>a {
  color: white;
}

.navbar-header .navbar-overview>ul>li:not(:last-child)::after {
  content: "\e930";
  margin: 0px .5rem;
  font-family: 'Feather';
}

.adsign-modal .ant-modal-header {
  border-radius: 0.25rem 0.25rem 0px 0px;
  justify-content: flex-start;
  background-color: var(--color-bg-modal-header);
  font-family: 'Inter', sans-serif;
  color: var(--color-text-black);
  border-bottom: 1px solid var(--color-border);
}

.adsign-modal .ant-modal-header .ant-modal-title {
  font-weight: 600;
  font-size: .9rem;
}

.adsign-modal .ant-modal-content {
  border-radius: 0.5rem;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.24);
}

.adsign-modal .ant-modal-close-x>i {
  padding: 0.25rem;
  background-color: var(--color-bg-gray);
  border-radius: 50%;
  color: var(--color-text-gray);
}

.adsign-modal .ant-input-number{
  width: 100% !important;
}

.adsign-modal input.ant-input,
.adsign-modal input.ant-input-number-input,
.adsign-modal textarea.ant-input {
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.adsign-modal input.ant-input::placeholder,
.adsign-modal textarea.ant-input::placeholder {
  color: var(--color-text-light);
}

.adsign-modal input.ant-input:focus,
.adsign-modal textarea.ant-input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-input-focus) !important;
}

.adsign-modal .ant-form-item-label>label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}

.adsign-modal .ant-modal-footer {
  justify-content: flex-end;
}

.adsign-modal .button-submit {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1.5rem;
  height: auto;
  border: 1px solid var(--primary-button);
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.adsign-modal .button-cancel {
  color: #fff;
  background-color: #fff;
  border-radius: 0.35rem;
  padding: 0.5rem 1.5rem;
  height: auto;
  border: 1px solid var(--cancel-button);
  font-weight: 600;
  cursor: pointer;
  color: var(--cancel-button);
}

.adsign-modal .modal-content-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.adsign-modal .modal-content-delete>img {
  width: 150px;
  height: 150px;
  margin: 1rem 0px;
}

.adsign-modal .modal-content-delete>p.modal-content-title {
  font-weight: 600;
  color: var(--color-text-black);
  margin-bottom: .5rem;
  font-size: 1rem;
}

.adsign-modal .modal-content-delete>p.modal-content-description {
  padding: 0px 1rem;
  text-align: center;
  color: var(--color-text-gray-darker);
  font-size: .8rem;
  line-height: 1rem;
  margin-bottom: 0px;
}

.adsign-modal .modal-content-delete>p.modal-content-subtitle {
  color: var(--color-active);
  margin: 1rem 0px;
  font-weight: 600;
}

.adsign-modal .modal-footer-delete {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.adsign-modal .modal-footer-delete .button-cancel {
  margin-right: 0.5rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.35rem;
  padding: 0.5rem 1.5rem;
  height: auto;
  border: 1px solid var(--color-border);
  font-weight: 600;
  cursor: pointer;
  color: var(--color-text-black);
}

.adsign-modal .modal-footer-delete .button-delete {
  margin-left: 0.5rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--cancel-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1.5rem;
  height: auto;
  border: 1px solid var(--cancel-button);
  font-weight: 600;
  cursor: pointer;
  color: #fff;
}

.adsign-modal .modal-footer-delete button.is-loading {
  cursor: not-allowed;
  opacity: .5;
}

.adsign-modal .modal-footer-delete button.is-loading>i {
  margin-right: .5rem;
}


.adsign-modal .form>.form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}

.adsign-modal .form {
  margin-bottom: 1rem !important;
}

.adsign-modal .form .ant-picker {
  width: 100%;
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.adsign-modal .form .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}
.media-form {
  width: 100%;
}

.media-form .form-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.media-form .form-wrapper > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.media-form .form-wrapper > div:last-child {
  width: 100%;
  margin-left: 1rem;
}

.media-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}

.media-form .form-wrapper .form {
  margin-bottom: 1rem !important;
}

.media-form .upload-image-preview {
  position: relative;
  width: 100%;
}

.media-form .upload-image-preview > img,
.media-form .upload-image-preview > video {
  width: 100%;
  border-radius: 10px;
  height: 140px;
  object-fit: contain;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.media-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--color-bg-pink);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.media-form .upload-drag-and-drop {
  padding: 1.5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.ant-upload {
  width: 100%;
}

.media-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop:hover,
.media-form .upload-drag-and-drop:hover > i,
.media-form .upload-drag-and-drop:hover > p,
.media-form .upload-drag-and-drop.active,
.media-form .upload-drag-and-drop.active > i,
.media-form .upload-drag-and-drop.active > p {
  border-color: var(--color-input-focus);
  /* color: var(--color-active); */
}

.media-form .button-wrapper {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.media-form .button-wrapper .button-primary {
  margin-right: 1rem;
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.media-form .button-wrapper .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.media-form .form-group {
  display: flex;
  align-items: flex-start;
}

.media-form .form input[type='color'] {
  background-color: rgb(243, 156, 18);
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0px;
  border-radius: 5px;
}

.media-form .input-group {
  display: flex;
  align-items: center;
}

.media-form .input-group > div:last-child {
  background-color: #fff;
  padding: 0.5rem 1em;
  color: var(--color-text-light);
  border: 1px solid var(--color-border);
}

.media-form .card-adsign .card-title > p {
  font-weight: normal;
}

.media-form .card-adsign-content .button-link {
  background-color: transparent;
}

.media-form .card-checkbox span {
  font-weight: normal;
}

.media-form .card-adsign-content .copy-popup {
  right: 0;
}

.datepicker-adsign {
  width: 100%;
  border: 1px solid var(--color-secondary) !important;
  border-radius: 0.3rem !important;
}

.media-form input.ant-input, .media-form textarea.ant-input {
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.media-form input.ant-input::placeholder,
.media-form textarea.ant-input::placeholder {
  color: var(--color-text-light);
}

.media-form input.ant-input:focus, .media-form textarea.ant-input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-input-focus) !important;
}

.media-form .btn-primary {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: none;
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.media-form .playlist-wrapper.active {
  border-color: var(--color-input-focus);
}

.media-form .playlist-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-input);
  border: 1px solid var(--color-border);
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  padding: 0.5rem 1rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100% !important;
  margin: 0px !important;
}

.media-form .playlist-wrapper > .playlist-selection > span.playlist-placeholder {
  color: var(--color-text-light)
}

.media-form .playlist-wrapper i.playlist-closed {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}

.media-form .playlist-wrapper i.playlist-open {
  transform: rotate(180deg);
  transition: all 250ms ease-in-out;
}

.media-form .playlist-dropdown {
  width: 100%;
}

.media-form .ant-input-number {
  width: 100% !important;
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-right: 0px !important;
  border-radius: 0.25rem 0px 0px 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
}

.media-form .ant-input-number.ant-input-number-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.media-form .ant-input-number  input {
  border-radius: 0px !important;
  padding: 0.5rem 1rem !important;
}

.media-form .ant-picker {
  width: 100%;
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.media-form .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.media-form .upload-drag-and-drop > p.upload-title {
  font-weight: 700;
  color: var(--color-text-light);
  margin-bottom: 0.5rem;
}

.media-form .upload-drag-and-drop > p.upload-title span.upload-highlight {
  color: var(--primary-button);
}

.media-form .upload-drag-and-drop > p.upload-subtitle {
  color: var(--color-text-gray-dark);
  font-weight: 400;
  font-size: .8rem;
}

.media-form .playlist-wrapper > .playlist-dropdown > ul > li > div.playlist-filter > .playlist-filter-radio.radio-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.media-form .checkbox {
  width: 16px;
  height: 16px;
  background-color: var(--color-bg-radio);
  border: 1px solid var(--color-border-radio);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-form .checkbox.active {
  border-color: var(--primary-button);
  background-color: var(--primary-button) !important;
  color: #fff;
}

.media-form .checkbox > .checkbox-checked > i {
  font-size: .6rem;
}

.media-form .ant-select-selector {
  width: 100%;
  background-color: var(--color-input) !important;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.15rem 1rem !important;
  height: auto !important;
}

.media-form .ant-select-open .ant-select-selector, .media-form .ant-select-focused .ant-select-selector {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}